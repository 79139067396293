import { createRouter, createWebHistory } from 'vue-router'
// import AllProjects from '../views/AllProjects.vue'
import store from '../store'
import api from '../tools/api';
import { toast } from 'vue-sonner'



const routes = [
  {
    path: '/',
    name: 'My dashboard space',
      component: () => import(/* webpackChunkName: "about" */ '../views/myAccount.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded whenf the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/list_filters',
    name: 'Matching filters - Personalize your selection criteria',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ListMyFilters.vue')
  },
  {
    path: '/link_plus',
    name: 'My Link+ space - Generate your invitations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GenerateLinkPlus.vue')
  },
  {
    path: '/list_link_plus',
    name: 'My Link+ space - View all invitations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ListLinkPlus.vue')
  },
  {
    path: '/manage_link_plus/:lid',
    name: 'Manage my Link+',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageLinkPlus.vue')
  },
  {
    path: '/my_link_plus/:aid',
    name: 'Startup Application',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewLinkPlusPublic.vue')
  },
  // {
  //   path: '/azeaze',
  //   name: 'aze aze',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/azeazeVue.vue')
  // },
  {
    path: '/startup_with_filters/:search_field/:pid',
    name: 'List startups and filters',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupsListWithFilter.vue')
  },
  {
    path: '/new_startup_with_filters/:search_field/:pid/:pool',
    name: 'My startup list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewStartupsListWithFilter.vue')
  },
  {
    path: '/my_pools/:search_field/:pid/',
    name: 'My lists of candidates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PoolsList.vue')
  },
  {
    path: '/startup_with_filters_graph',
    name: 'List startups and filters - Graph view',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupsListWithFilterGraph.vue')
  },
  {
    path: '/startup_with_filters_graph/:search_field/:filterstoapply/:pid',
    name: 'List startups and filters - Graph view - Prefilter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupsListWithFilterGraph.vue')
  },
  {
    path: '/view_filter/:filter',
    name: 'View and edit a filter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewFilter.vue')
  },
  {
    path: '/myaccount',
    name: 'My Account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/myAccount.vue')
  },
  {
    path: '/startups_ecosystem',
    name: 'My ecosystem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupsEcosystem.vue')
  },
  {
    path: '/startups_stats',
    name: 'My dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupsStats.vue')
  },
  {
    path: '/startups_stats_list/:filterstoapply',
    name: 'List of startups filtered',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupsStatsList.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue'),
    meta: { roleRestriction: 'Admin'}
  },
  {
    path: '/register_admin',
    name: 'registerAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register_admin.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/admin/all_projects',
    name: 'AllProjectsAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/list_adminprojects.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/admin/all_startups',
    name: 'AllStartupsInDB',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/StartupsEcosystemAdmin.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },

  {
    path: '/admin/partial_startups',
    name: 'PartialStartups',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ListPartialStartups.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/expert/my_projects',
    name: 'Expert - All my projects',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertProjectsList.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Expert'}
  },
  {
    path: '/admin/my_projects',
    name: 'Admin - All my projects',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminProjectsList.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },

  {
    path: '/admin/manage_users',
    name: 'Admin - Manage users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminManageUsers.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/admin/manage_users_admin',
    name: 'Admin - Manage admin users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminManageUsersAdmin.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/admin/project/:id',
    name: 'Admin Project',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminOneProject.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
  path: '/expert/project/:id',
  name: 'Expert One Project dash',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertOneProject.vue'),
  // beforeEnter: () => {
  //   // reject the navigation
  //     const user_role = store.getters['role']
  //     if(user_role != 'Admin')return false
  // },
  meta: {roleRestriction: 'Expert'}
},
  {
    path: '/admin/business_challenge/:projectid',
    name: 'Admin Business Challenge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/businessChallengeAdmin.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },

  {
    path: '/expert/business_challenge/:projectid',
    name: 'Review and sharp Business Challenge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/businessChallengeExpert.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Expert'}
  },
  {
    path: '/admin/dict',
    name: 'dictAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/dictView.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
     meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/register_confirm/:id',
    name: 'Confirm your account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/registerConfirm.vue')
  },
  {
    path: '/selectYourChallenge',
    name: 'Select your challenge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SelectChallengeView.vue')
  },
  // {
  //   path: '/selectYourChallenge',
  //   name: 'Select your challenge',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SelectChallengeView.vue')
  // },
  {
    path: '/project_init/:projectid',
    name: 'Intialize your project info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/QuickProjectCreation.vue')
  },
  {
    path: '/business_challenge_space',
    name: 'Business Challenge Space',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessChallengeSpace.vue')
  },
  {
    path: '/business_challenge_space/:projectid',
    name: 'Business Challenge Space',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessChallengeSpace.vue')
  },
  {
    path: '/scouting_space/:search_field_id/:projectid',
    name: 'Scouting Space',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ScoutingSpace.vue')
  },
  {
    path: '/admin/scouting_brief/:search_field_id/:projectid',
    name: 'Admin scouting brief',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminScoutingBrief.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/expert/scouting_brief/:search_field_id/:projectid',
    name: 'Expert scouting brief',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertScoutingBrief.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Expert'}
  },
  {
      path: '/expert/scouting_brief_complete/:search_field_id/:projectid',
    name: 'Expert complete scouting brief',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertScoutingBriefComplete.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Expert'}
  },
  {
      path: '/admin/scouting_brief_complete/:search_field_id/:projectid',
    name: 'admin complete scouting brief',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminScoutingBriefComplete.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/admin/candidatespool/:search_field_id/:projectid',
    name: 'Admin candidates pools management',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminCandidatePool.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/expert/candidatespool/:search_field_id/:projectid',
    name: 'Expert candidates pools management',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertCandidatePool.vue'),
    // beforeEnter: () => {
    //   // reject the navigation
    //     const user_role = store.getters['role']
    //     if(user_role != 'Admin')return false
    // },
    meta: {roleRestriction: 'Expert'}
  },
  {
    path: '/admin/startup_alt/:id',
    name: 'Admin - Manage startup profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminStartup.vue'),
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/admin/startup/:id/:projectid',
    name: 'Admin Startup basic info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminStartup.vue'),
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/expert/startup/:id/',
    name: 'Expert Startup info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertStartup.vue'),
    meta: {roleRestriction: 'Expert'}
  },
  {
    path: '/expert/startup/:id/:projectid',
    name: 'Expert Startup basic info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertStartup.vue'),
    meta: {roleRestriction: 'Expert'}
  },
  {
    path: '/expert/startup_info/:id/:projectid',
    name: 'Expert Startup basic info when submit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertStartupInfo.vue'),
    meta: {roleRestriction: 'Expert'}
  },
  {
    path: '/expert/assessment/:search_field_id/:projectid',
    name: 'Expert Assessment space',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/expert/ExpertAssessment.vue'),
    meta: {roleRestriction: 'Expert'}
  },
  {
    path: '/admin/assessment/:search_field_id/:projectid',
    name: 'Admin Assessment space',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminAssessment.vue'),
    meta: {roleRestriction: 'Admin'}
  },
  {
    path: '/all_my_projects',
    name: 'All my projects',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AllProjects.vue')
  },
  {
    path: '/projet/:id',
    name: 'My project',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OneProject.vue')
  },
  {
    path: '/candidatespool/:search_field_id/:projectid',
    name: 'Candidates pool',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CandidatePool.vue')
  },
  {
    path: '/startup/:id/:projectid',
    name: 'Startup basic info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupInfoBase.vue')
  },
  {
    path: '/startup/:id',
    name: 'Startup profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupInfoBase.vue')
  },
  {
    path: '/startup_report/:searchfield/:startup/:projectid',
    name: 'Startup Info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupInfo.vue')
  },
  {
    path: '/assessment/:searchfield/:projectid',
    name: 'Assessment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AssessmentSpace.vue')
  },
  {
    path: '/compare_startup/:searchfield/:a/:b/:projectid',
    name: 'Compare startups',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupCompare.vue')
  },
  {
    path: '/compare_startup_to_assess/:searchfield/:a/:b/:c?',
    name: 'Compare startups to assess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StartupBasicCompare.vue')
  },
  {
    path: '/my_candidate_selection/:sid/:pid',
    name: 'My Candidate Selection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchFieldSummary.vue')
  },
  {
    path: '/summary/:pid',
    name: 'My project summary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectSummary.vue')
  },
  {
    path: '/unauthorize',
    name: 'unauthorize',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/unauthorize.vue')
  },
  {
    path: '/manage_startup_info/:sid',
    name: 'Manage my startup profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PublicAdminStartup.vue'),

  },


  {
    path: '/internalassessment_creation/:id?',
    name: 'Create an internal assessment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InternalAssessmentCreation.vue')
  },

  {
    path: '/internalassessment_preview/:id',
    name: 'Preview an internal assessment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InternalAssessmentPreview.vue')
  },

    {
      path: '/judge_assessment/:token/:id',
      name: 'Judge assessment',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/JudgeAssessment.vue')
    },
    {
      path: '/judge_vote/:token/:id/:sid',
      name: 'Judge - Assess the startup',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/JudgeAssessStartup.vue')
    },
    {
      path: '/admin/manage_data_policy',
      name: 'Manage data policy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/admin/DataPolicy.vue'),
            meta: {roleRestriction: 'Admin'}
    },
    {
      path: '/datapolicy',
      name: 'Data policy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/DataPolicyPublic.vue'),
    },
    {
      path: '/terms_and_conditions',
      name: 'Terms and Conditions',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/TermsAndConditionsPublic.vue'),
    },
    {
      path: '/admin/manage_terms_and_conditions',
      name: 'Manage terms and conditions',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/admin/TermsAndConditions.vue'),
            meta: {roleRestriction: 'Admin'}
    },
    {
      path: '/admin/manage_default_data/:dataid',
      name: 'Manage default datas',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/ManageDefaultDatas.vue'),
            meta: {roleRestriction: 'Admin'}
    },
    {
      path: '/manage_assessments',
      name: 'Manage assessments',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/ManageAssessments.vue')
    },
    {
      path: '/do_assessment/:token/:id',
      name: 'Complete assessment',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/LicenceAssessment.vue')
    },
    {
      path: '/assessment_results/:id',
      name: 'Assessment results',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AssessmentResults.vue')
    },
    {
      path: '/potential_partners/:pid/:sid',
      name: 'Shortlist of potential partners',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/PotentialPartners.vue')
    },
    {
      path: '/innopearl_assessment/:id',
      name: 'Innopearl do assessment',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/InnoAssessment.vue')
    },
    {
      path: '/innopearl_assess_startup/:id/:sid',
      name: 'Innopearl assess the startup',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/InnoAssessStartup.vue')
    },
    {
      path: '/innopearl_assessment_results/:id',
      name: 'Innopearl assessment results',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/InnopearlAssessmentResults.vue')
    },

    {
      path: '/expert/manage_assessments',
      name: 'Expert - Manage assessments',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/expert/ManageAssessments.vue'),
      meta: {roleRestriction: 'Expert'}
    },
    {
      path: '/admin/manage_assessments',
      name: 'Admin - Manage assessments',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/admin/ManageAssessments.vue'),
      meta: {roleRestriction: 'Admin'}
    },
    {
      path: '/admin/internalassessment_creation/:id?',
      name: 'Admin - Create an internal assessment',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/admin/InternalAssessmentCreation.vue'),
      meta: {roleRestriction: 'Admin'}

    },
    {
      path: '/admin/internalassessment_preview/:id',
      name: 'Admin - Preview an internal assessment',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/InternalAssessmentPreview.vue'),
      meta: {roleRestriction: 'Admin'}

    },

    {
      path: '/admin/manage_assessments_criterias',
      name: 'Admin - Manage assessment criterias ',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/admin/ManageCriterias.vue'),
      meta: {roleRestriction: 'Admin'}

    },

    {
      path: '/admin/manage_users_criterias',
      name: 'Admin - Manage users criterias ',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/admin/ManageUserCriterias.vue'),
      meta: {roleRestriction: 'Admin'}

    },

    {
      path: '/admin/manage_next_steps',
      name: 'Admin - Manage next steps',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminNextSteps.vue'),
      meta: {roleRestriction: 'Admin'}

    },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
    else{
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
   const isAuthenticated = store.getters['authenticated']
  if(to.name == "Judge assessment")next();
  else if(to.name == "Startup Application")next();
  else if(to.name == "Judge - Assess the startup")next();
  else if(to.name == "Manage my startup profile")next();
  else if(to.name == "Confirm your account")next();
  else if(to.name == "login")next();
  else if(to.name == "register")next();
  else if(to.name == "unauthorize")next();
  else  if(to.name != 'unauthorize' &&  to.name != 'login' && to.name !='register' && to.name != 'Confirm your account' && !isAuthenticated) next({ name: 'login' })
   else {
  //   //if not a free route check if is removed
  //  if (to.name == 'unauthorize' || to.name == 'Judge assessment' ||  to.name == 'login' || to.name == 'register' || to.name == 'Confirm your account' || to.name == 'Startup Application' ) next();
    api.get(process.env.VUE_APP_API_URL+"/restrict/myaccount").then( (r) => {
      console.log("MyAccoutn",r.data);
      if(r.data.licence_end){

        var today = new Date();
        var licence_end = new Date(r.data.licence_end.date);
        if(today > licence_end){
          toast.error("Your licence has expired");
          next({ name: 'login' });
        }
      }
      if(r.data.licence_start){

        var today3 = new Date();
        var licence_start = new Date(r.data.licence_start.date);
        if(today3 < licence_start){
          toast.error("Your licence is not active yet");
          next({ name: 'login' });
        }
      }
      if(r.data.isRemoved){
              console.log("MyAccoutn is removed",r.data);
           next({ name: 'login' });
      }
      else{
        api.get(process.env.VUE_APP_API_URL+"/restrict/projects").then( (r) => {

          store.commit('UPDATE_MYPROJECTS',r.data);


          if(to.meta.roleRestriction){
            console.log("route restriction");
            console.log(to.meta.roleRestriction);
             const user_role = store.getters['role'];

             if(user_role != to.meta.roleRestriction){
                next({ name: 'unauthorize' })
             }
             else{
              if(isAuthenticated)store.commit('UPDATE_NOTIFICATION')
              next();
              }
          }
          else {
            // Mettre à jour le store Vuex
            if(isAuthenticated)store.commit('UPDATE_NOTIFICATION')
            next();
        }
          }).catch( (e) => console.log(e));
      }
    }).catch( (e) => console.log(e));

   }
})


export default router
